import { createStore } from 'vuex'

export default createStore({
  state: {
    userData: {},
    listData: [],
    xiangqingdata: {} as any,
    alllisttype: [] as any,
    allData: [] as any[],
    userraddress: {} as any,
    searchdata: [] as any
  },
  getters: {
  },
  mutations: {
    updateUserData(state, data) {
      state.userData = data
    },
    updatelistData(state, data) {
      state.listData = data
    },
    updataxiangqingdata(state, data: any) {

      state.xiangqingdata = data


    },
    updateAllData(state, data) {
      state.allData = data
    },
    updatealllisttype(state, data) {
      state.alllisttype = data
    },
    updateuserraddress(state, data) {
      state.userraddress = data
    },
    updateSearchdata(state, data) {
      state.searchdata = data
    },






  },
  actions: {
  },
  modules: {
  }
})
