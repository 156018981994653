import request from "@/utils/request";

//用户登录
export function usersignin(phone:String,pwd:String) {
    return request({
        url: `users/signin?phone=${phone}&pwd=${pwd}`
        
    })
}
//用户注册
export function userslogon(name:String,phone:String,pwd:String,imgurl:String) {
    return request({
        url: `users/logon`,
        method: 'POST',
        data:{
            name,
            phone,
            pwd,
            imgurl
        }
        
    })
}
//修改用户接口
export function userupdate(name:String,pwd:String,phone:String,imgurl:String,title:String,id:Number) {
    return request({
        url: `users/userupdate`,
        method: 'POST',
        data:{
            name,
            phone,
            pwd,
            imgurl,
            title,
            id

        }
        
    })
}

//查询数据类型
export function listDataAlltype() {
    return request({
        url: `users/listDataAlltype`,
        method: 'POST',
       
        
    })
}


//查询所有数据
export function listDataAll() {
    return request({
        url: `users/listDataAll`,
        method: 'POST',
       
        
    })
}

//根据数据类型查询数据
export function listData(title:String,page:Number,pagesize:Number) {
    return request({
        url: `users/seletegoods`,
        method: 'POST',
        data:{
            title,
            page,
            pagesize
        }
        
    })
}
//添加收藏
export function addShowCang(shoppingid:Number,userid:Number) {
    return request({
        url: `users/addsoucang`,
        method: 'POST',
        data:{
            shoppingid,
            userid
        }
        
    })
}
//删除收藏
export function removeshoucang(shoppingid:Number,userid:Number) {
    return request({
        url: `users/removeshoucang`,
        method: 'POST',
        data:{
            shoppingid,
            userid
        }
        
    })
}
//修改查询该用户所有收藏
export function seleteshoucang(userid:Number) {
    return request({
        url: `users/seleteshoucang`,
        method: 'POST',
        data:{
            userid
        }
        
    })
}
//添加购物车
export function addgouwuches(shoppingid:Number,userid:Number) {
    return request({
        url: `users/addgouwuches`,
        method: 'POST',
        data:{
            shoppingid,
            userid
        }
        
    })
}
//查询购物车
export function selectgouwuche(userid:Number) {
    return request({
        url: `users/selectgouwuche`,
        method: 'POST',
        data:{
            userid
        }
        
    })
}
//删除购物车
export function removegouwuche(userid:Number,shoppingid:Number) {
    return request({
        url: `users/removegouwuche`,
        method: 'POST',
        data:{
            userid,
            shoppingid
        }
        
    })
}
//添加浏览记录
export function adduserhistory(userid:Number,shoppingid:Number) {
    return request({
        url: `users/adduserhistory`,
        method: 'POST',
        data:{
            userid,
            shoppingid
        }
        
    })
}

//查询用户浏览记录
export function selecthistory(userid:Number) {
    return request({
        url: `users/selecthistory`,
        method: 'POST',
        data:{
            userid,
        }
        
    })
}

//删除·用户浏览记录
export function removehistory(userid:Number,shoppingid:Number) {
    return request({
        url: `users/removehistory`,
        method: 'POST',
        data:{
            userid,
            shoppingid
        }
        
    })
}
//增加用户收获地址
export function adduseraddress(name:String,address:String,tel:String,isDefault:String,userid:Number) {
    return request({
        url: `users/adduseraddress`,
        method: 'POST',
        data:{
            userid,
            name,
            address,
            tel,
            isDefault,
        }
    })
}


//查询用户收获地址
export function selectuseraddress(userid:Number) {
    return request({
        url: `users/selectuseraddress`,
        method: 'POST',
        data:{
            userid,
          
        }
    })
}


//修改用户收获地址
export function updateuserraddress(name:String,address:String,tel:String,userid:Number,id:Number) {
    return request({
        url: `users/updateuserraddress`,
        method: 'POST',
        data:{
            userid,
            name,
            address,
            tel,
            id
          
        }
    })
}
   

//修改用户收获地址
export function removeuseraddresss(id:Number) {
    return request({
        url: `users/removeuseraddresss`,
        method: 'POST',
        data:{
            id
        }
    })
}
//修改用户收获地址
export function adduseroders(userid:Number, shoppingid:Number,num:Number,price:Number,date:String,addressid:Number,orderid:any) {
    return request({
        url: `users/adduseroder`,
        method: 'POST',
        data:{
            userid,
            shoppingid,
            num,
            price,
            date,
            addressid,
            orderid
        }
    })
}

// 查询用户订单
export function selectuserodre(userid:Number,state:Number) {
    return request({
        url: `users/selectuserodre`,
        method: 'POST',
        data:{
            userid,
            state
        }
    })
}

// /查询用户全部订单
export function selectuserodreall(userid:Number) {
    return request({
        url: `users/selectuserodreall`,
        method: 'POST',
        data:{
            userid,
        }
    })
}
// /查询用户全部订单
export function removeuserodre(userid:Number,orderid:String) {
    return request({
        url: `users/removeuserodre`,
        method: 'POST',
        data:{
            userid,
            orderid
        }
    })
}
//模糊查询数据
export function likelistDataAll(name:String,page:Number,pagesize:Number) {
    return request({
        url: `users/likelistDataAll`,
        method: 'POST',
        data:{
            name,
            page,
            pagesize,
        }
    })
}

//随机生成数据
export function rdomlistDataAll(nums:Number) {
    return request({
        url: `users/rdomlistDataAll`,
        method: 'POST',
        data:{
            nums
        }
      
    })
}
// 搜索接口
export function liketypenames(type:String,names:String,page:Number,pagesize:Number) {
    return request({
        url: `users/liketypenames`,
        method: 'POST',
        data:{
            type,
            names,
            page,
            pagesize
        }
      
    })
}


 



