import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/xiangqing',
    name: 'xiangqing',
    component: () => import('@/views/xiongqing/Index.vue')
  },
  {
    path: '/canshu',
    name: 'canshu',
    component: () => import('@/views/xiongqing/CanShu.vue')
  },
  {
    path: '/enllo',
    name: 'enllo',
    component: () => import('@/views/EnlloView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/HomeView.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search/Index.vue')
  },
  {
    path: '/userhome',
    name: 'userhome',
    component: () => import('@/views/user/UserHome.vue'),
    children: [
      {
        path: '',
        name: 'user',
        component: () => import('@/views/user/Index.vue')
      },
      {
        path: 'wsh',
        name: 'wsh',
        component: () => import('@/views/user/WeiShouHuo.vue')
      },
      {
        path: 'fahuo',
        name: 'fahuo',
        component: () => import('@/views/user/FaHuo.vue'),
      },
      {
        path: 'all',
        name: 'all',
        component: () => import('@/views/user/AllView.vue'),
      },
      {
        path: 'fukuan',
        name: 'fukuan',
        component: () => import('@/views/user/FuKuan.vue'),
      },
    
      {
        path: 'shouh',
        name: 'shouh',
        component: () => import('@/views/user/ShouView.vue')
      },
      {
        path: 'youhui',
        name: 'youhui',
        component: () => import('@/views/user/YouHuiJuan.vue')
      },
      
    ]
  },
  
  {
    path: '/gouwuche',
    name: 'gouwuche',
    component: () => import('@/views/Gouwuche.vue')
  },
  {
    path: '/updateuser',
    name: 'updateuser',
    component: () => import('@/views/user/UpdateUser.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/HistoryUser.vue')
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('@/views/user/Address.vue'),
    
  },
  {
    path: '/bianjiaddress',
    name: 'bianjiaddress',
    component: () => import('@/views/user/BianjiAddress.vue'),
    
  },
  {
    path: '/updateaddress',
    name: 'updateaddress',
    component: () => import('@/views/user/UpdateAddress.vue')
    
  },
  {
    path: '/shoucang',
    name: 'shoucang',
    component: () => import('@/views/user/ShouCang.vue')
  },

  {
    path: '/fenlei',
    name: 'fenlei',
    component: () => import('@/views/fenlei/Index.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
