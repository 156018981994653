import axios from 'axios'

// 创建 axios 对象
const instance = axios.create({
    baseURL: 'https://plomaster.cc/',
    timeout: 20000
})


instance.interceptors.request.use(function (config) {

    return config;
}, function (error) {
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});
export default instance
